import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import ReactSelect from 'react-select';
import { appointments, users } from 'helpers/api_url';
import { set } from 'lodash';
import { useSelector } from 'react-redux';
import { event } from 'common/data';
import { isBefore, isSameDay, parseISO } from 'date-fns';
import MakeAppointmentModal from './MakeAppointmentModal';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import { generateEventFromAppointment } from 'helpers/event_helper';

const index = () => {
	const [appointmentModalData,setAppointmentModalData] = useState({status:false,data:{}});
	const toggleAppointmentModal = ()=>setAppointmentModalData(state=>({...state,status:!appointmentModalData.status}))
	const [profUsers,setProfUsers] = useState([]);
	const authUser = useSelector(state => state.Login.authUser);
	const [events, setEvents] = useState([]);
	const dispatch = useDispatch();
	useEffect(()=>{
		dispatch(changePreloader({status:true,message:''}))
		appointments.list().then(res=>{
			const ev = [];
			res.data.appointment.map((appointment)=>ev.push(generateEventFromAppointment(appointment)))
			setEvents(ev);
			toggleAppointmentModal();
		}).catch(e=>toast.error(e.response?e.response.data.message:e.message))
		.finally(()=>dispatch(changePreloader({status:false,message:''})));	
		users.proffessional().then(res=>setProfUsers(res.data.users)).catch(e=>toast.error(e.response?e.response.data.message:e.message));
	},[])
	const handleMakeAppointmentSubmit = e=>{
		e.preventDefault();
		const formData = new FormData(e.target);
		dispatch(changePreloader({ status: true, message: '' }))
		appointments.add(formData).then(res=>{
			setEvents(state=>[...state,generateEventFromAppointment(res.data.appointment)]);
			toast.success(res.message);
			toggleAppointmentModal();
		}).catch(e=>toast.error(e.response?e.response.data.message:e.message))
		.finally(()=>dispatch(changePreloader({status:false,message:''})));	
	}
	return (
		<>
			<div className="page-content">
				<Container fluid>
					<Card>
						<CardHeader className='d-flex justify-content-between align-items-center'>
							<CardTitle>Make An Appointment</CardTitle>
							<button onClick={toggleAppointmentModal} className='btn btn-soft-success'>+ New</button>
						</CardHeader>
						<CardBody>
							<FullCalendar plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin,]}
								slotDuration={"00:15:00"}
								handleWindowResize={true}
								themeSystem="bootstrap"
								headerToolbar={{
									left: "prev,next today",
									center: "title",
									right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
								}}
								events={events}
								dateClick={function (info) {
									const currentDate = new Date();
  									const dateToCompare = parseISO(info.dateStr);
									if(!isBefore(dateToCompare, currentDate) || isSameDay(dateToCompare, currentDate)) 
									setAppointmentModalData({status:true,data:{date:info.dateStr}});
								}}
							/>
						</CardBody>
					</Card>
				</Container>
				<Modal centered={true} isOpen={appointmentModalData.status} toggle={toggleAppointmentModal}>
					<ModalHeader toggle={toggleAppointmentModal}>
						<span>New Appointment Details</span>
					</ModalHeader>
					<ModalBody>
						<form onSubmit={handleMakeAppointmentSubmit}>
							<input type='hidden' value={authUser?.id} name='user_id' />
							<div className='mb-2'>
								<Label htmlFor='prof_user_id' className="form-label">Select A Professional</Label>
								<Card>
									<ReactSelect 
										isSearchable 
										id='prof_user_id'
										name='prof_user_id'
										options={profUsers?.map(u=>({value:u.id,label:`${u.first_name} ${u.last_name} (${u?.role?.name})`}))}
									/>
								</Card>
							</div>
							<Row className='mb-2'>
								<Col xs={6}>
									<div>
										<Label htmlFor='title' className="form-label">Title</Label>
										<input type="text" className='form-control' name='title' id='title' />
									</div>
								</Col>
								<Col xs={6}>
									<div>
										<Label htmlFor='date_of_apointment' className="form-label">Date of Apointment</Label>
										<input 
											defaultValue={appointmentModalData?.data?.date} 
											type="date" 
											className='form-control' 
											name='date_of_apointment' 
											id='date_of_apointment' 
										/>
									</div>
								</Col>
							</Row>
							<div className="mb-3">
								<Label htmlFor='description' className="form-label">Appointment Descriptions</Label>
								<textarea className='form-control' name='description' id='description' rows={5}></textarea>
							</div>
							<div className="mb-3 center">
								<Button type='submit' className='form-control bg-primary' >Add</Button>
							</div>
						</form>
					</ModalBody>
				</Modal>
			</div>
		</>
	)
}
export default index