import React, { useState } from 'react'
import ReactSelect from 'react-select'
import { Row, Col, Card, CardBody, Form, Input, Collapse, Label } from 'reactstrap'
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
import { Link } from 'react-router-dom';
import { options,experienceData,jobType,jobCategories1,JobComfirtable,listData} from './common/data';
const ApplyResume = () => {
    const [advancefilter, setAdvanceFilter] = useState(false);
    const toggle = () => setAdvanceFilter(!advancefilter);
    const activeBtn = (ele) => {if (ele.closest("button").classList.contains("active")) { ele.closest("button").classList.remove("active");} else {ele.closest("button").classList.add("active");}}
    return (
        <React.Fragment>
            <Row>
                <Col lg={4} xs={12}>
                    <Card>
                        <CardBody>
                            <h5>Employee Filter</h5>
                            <Form>
                                <Row className="g-3">
                                    <Col lg={12} xs={12}>
                                        <div className="position-relative">
                                            <Input type="text" id="searchJob" autoComplete="off" placeholder="Search  Empolyee" />
                                        </div>
                                    </Col>
                                    <Col lg={12} xs={12}>
                                        <div className="position-relative">
                                            <Input type="text" id="locationInput" autoComplete="off" placeholder="Search Location" />
                                        </div>
                                    </Col>
                                    <Col lg={12} xs={12}>
                                        <div className="position-relative">
                                            <ReactSelect className="select2" options={options} />
                                        </div>
                                    </Col>
                                    <Col lg={12} xs={12}>
                                        <div className="position-relative">
                                            <FlatPickr className="form-control" name="joiningDate" placeholder="Select date" options={{ dateFormat: "d M, Y" }} />
                                        </div>
                                    </Col>
                                    <Col lg={12} xs={12}>
                                        <div className="position-relative h-100 hstack gap-3">
                                            <button type="button" className="btn btn-sm btn-primary h-100 w-100"><i className="bx bx-search-alt align-middle"></i> Find Jobs</button>
                                            <a href="#!" className="btn btn-sm btn-secondary h-100 w-100" onClick={toggle}><i className="bx bx-filter-alt align-middle"></i> Advance</a>
                                        </div>
                                    </Col>
                                    <Collapse isOpen={advancefilter}>
                                        <Row>
                                            <Col lg={6} xs={12}>
                                                <Label htmlFor='experience'>Experience</Label>
                                                {
                                                    (experienceData || []).map((item, index) => (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${item.id}`} value={item.value} />
                                                            <Label className="form-check-label" htmlFor={`inlineCheckbox${item.id}`}>{item.label}</Label>
                                                        </div>
                                                    ))
                                                }
                                            </Col>
                                            <Col lg={6} xs={12}>
                                                <Label htmlFor="jobType" className="form-label fw-semibold">Job Type</Label>
                                                {
                                                    (jobType || []).map((item, index) => (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <Input type="checkbox" id={`inlineCheckbox${item.id}`} value={item.value} />
                                                            <Label className="form-check-label" htmlFor={`inlineCheckbox${item.id}`}>{item.label}</Label>
                                                        </div>
                                                    ))
                                                }
                                            </Col>
                                            <Col lg={6} xs={12}>
                                                <Label htmlFor="qualification" className="form-label fw-semibold">Qualification</Label>
                                                <Input type="text" className="form-control" id="qualificationInput" autoComplete="off" placeholder="Qualification" />
                                            </Col>
                                            <Col lg={6} xs={12}>
                                                <Label htmlFor="package" className="form-label fw-semibold">Package Drager</Label>
                                                <Input type="text" className="form-control" id="package" autoComplete="off" placeholder="Package" />
                                            </Col>
                                            <Col lg={12} xs={12}>
                                                <Label htmlFor="jobcomfirtable" className="form-label fw-semibold mt-2">Job Comfirtable</Label>
                                                {
                                                    (JobComfirtable || []).map((item, index) => (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${item.id}`} value={item.value} />
                                                            <Label className="form-check-label" htmlFor={`inlineCheckbox${item.id}`}>{item.label}</Label>
                                                        </div>
                                                    ))
                                                }
                                            </Col>
                                        </Row>
                                    </Collapse>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                        <h5>By Employee</h5>
                        {(jobCategories1 || []).map((item, key) => (<Link key={key} to="#!" className="px-3 py-2 rounded bg-light bg-opacity-50 d-block mb-2">{item.name}<span className="badge text-bg-info float-end bg-opacity-100">{item.category}</span></Link>))}
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={8} xs={12}>
                <Row>
                {(listData || []).map((item, key) => (
                    <Col xl={6} key={key}>
                        <Card>
                            <CardBody>
                                <div className="d-flex align-start mb-3">
                                    <div className="flex-grow-1">
                                        <span className={item.type === "Full Time" ? "badge badge-soft-success" : item.type === "Freelance" ? "badge badge-soft-info" : item.type === "Part Time" ? "badge badge-soft-danger" : "badge badge-soft-warning"}>
                                            {item.type}
                                        </span>
                                    </div>
                                    <button type="button" className="btn btn-light btn-sm like-btn" onClick={(e) => activeBtn(e.target)}>
                                        <i className="bx bx-heart"></i>
                                    </button>
                                </div>
                                <div className="text-center mb-3">
                                    <img src={item.img} alt="" className="avatar-sm rounded-circle" />
                                    <h6 className="font-size-15 mt-3 mb-1">{item.name}</h6>
                                    <p className="mb-0 text-muted">{item.designation}</p>
                                </div>
                                <div className="d-flex mb-3 justify-content-center gap-2 text-muted">
                                    <div>
                                        <i className='bx bx-map align-middle text-primary'></i> {item.location}
                                    </div>
                                    <p className="mb-0 text-center"><i className='bx bx-money align-middle text-primary'></i> ${item.experience} / hrs</p>
                                </div>
                                <div className="hstack gap-2 justify-content-center">
                                    {(item.skills || []).map((subItem, key) => (
                                        <span key={key} className="badge badge-soft-secondary">{subItem}</span>
                                    ))}
                                </div>

                                <div className="mt-4 pt-1">
                                    <Link to="/profile" className="btn btn-soft-primary d-block">View Profile</Link>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}
export default ApplyResume
