import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, CardFooter, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Label, Input, Button } from 'reactstrap'
import userprofile from "assets/images/users/avatar-5.jpg";
import StarRatings from "react-star-ratings";
import { Link } from 'react-router-dom';
import { AssetUrl, proffessionals } from 'helpers/api_url';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import ViewAvailabilityModal from './ViewAvailabilityModal';
export const ViewProfile = () => {
    const [menu, setMenu] = useState(false);
    const authUser = useSelector(state => state.Login.authUser);
    const [profileData, setProfileData] = useState();
    const dispatch = useDispatch();
    const [appointmentModalData,setAppointmentModalData] = useState({status:false,data:{}});
	const toggleAppointmentModal = ()=>setAppointmentModalData(state=>({...state,status:!appointmentModalData.status}))
    useEffect(() => {
        dispatch(changePreloader({ status: true, message: '' }))
        proffessionals.myprofile()
            .then(res => setProfileData(res?.data?.profile))
            .catch(e => console.log(e)).finally(() => dispatch(changePreloader({ status: false, message: '' })));
    }, []);
    const handleMakeAppointmentSubmit = e=>{
		e.preventDefault();
		const formData = new FormData(e.target);
        dispatch(changePreloader({ status: true, message: '' }))
		appointments.add(formData).then(res=>{
			toast.success(res.message);
			toggleAppointmentModal();
		}).catch(e=>toast.error(e.response?e.response.data.message:e.message))
		.finally(()=>dispatch(changePreloader({status:false,message:''})));
	}
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardBody className='border-bottom'>
                            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="float-end ms-2">
                                <DropdownToggle tag="i" className="text-muted"><i className="mdi mdi-dots-horizontal font-size-18"></i></DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="#">Share your story</DropdownItem>
                                    <DropdownItem href="#">Another action</DropdownItem>
                                    <DropdownItem href="#">Something else</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Row>
                                <Col lg={3} xs={6}>
                                    <div className="text-center mb-4 me-3">
                                        <img src={AssetUrl + authUser?.other_details?.profile_image} className="avatar-lg rounded-circle" alt={authUser?.first_name} />
                                    </div>
                                </Col>
                                <Col lg={9} xs={6}>
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <h5>{profileData ? profileData?.name?.toUpperCase() : `${profileData?.first_name?.toUpperCase()} ${profileData?.last_name?.toUpperCase()}`}</h5>
                                            <div className="text-muted ms-3">
                                                <StarRatings
                                                    rating={0}
                                                    starRatedColor="#F1B44C"
                                                    starEmptyColor="#74788d"
                                                    numberOfStars={5}
                                                    name="rating"
                                                    starDimension="14px"
                                                    starSpacing="3px"
                                                    className=""
                                                />
                                            </div>
                                        </div>
                                        <p className="text-muted mb-1">{profileData?.digree?.toUpperCase()}</p>
                                        <p className="text-default mb-1">{profileData?.profession}</p>
                                        <p className="text-success mb-0"> <span className="text-muted mb-0">{profileData?.speciality}</span></p>
                                        <p className="text-muted mb-3">{profileData?.experience || '-'} Years Experience</p>
                                        <div className="text-muted  me-3"><span className="pe-3 pt-4">4.5</span><StarRatings rating={4} starRatedColor="#F1B44C" starEmptyColor="#74788d" numberOfStars={5} name="rating" starDimension="14px" starSpacing="3px" className="" /></div>
                                        <p className="text-muted mb-0">{profileData?.description}</p>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardBody>
                            <Row>
                                <div className="col-sm-4">
                                    <h5 className="text-info fw-medium mb-2">{profileData?.address?.address}</h5>
                                    <p className="text-muted fw-bold mt-3 mb-1">City : {profileData?.address?.city.toUpperCase()}</p>
                                    <p className="text-muted fw-bold my-1">District : {profileData?.address?.district.toUpperCase()}</p>
                                    <p className="text-muted fw-bold my-1">{profileData?.address?.state?.toUpperCase()} - {profileData?.address?.pincode}</p>
                                    <p className="text-muted fw-bold my-1">{profileData?.address?.country.toUpperCase()}</p>
                                </div>

                                <div className="col-sm-4">
                                    <p className="fw-medium mt-0 mb-2 me-2"><h5 className='d-inline'>FEE : </h5> <i className="bx bx-rupee"></i> {profileData?.fee}</p>
                                    <div className="d-inline-flex align-items-center ">
                                        <Link to="#" className="m-1" id="bitcoin"><div className="avatar-xs"><span className="avatar-title bg-warning-subtle rounded-circle text-warning font-size-18"><i className="bx bx-credit-card"></i></span></div><UncontrolledTooltip placement="top" target="bitcoin">GPay, Paytm</UncontrolledTooltip></Link>
                                        <p className="text-muted pt-3">Online Payment Available</p>
                                    </div>
                                    <div>
                                        <ViewAvailabilityModal availability={profileData?.availability} />
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                        <CardFooter className="bg-transparent border-top">
                            <div className="text-end"><Button onClick={toggleAppointmentModal} className="btn btn-soft-success me-2 w-md"><i className="bx bxs-bolt"></i> Book Appointment</Button></div>
                        </CardFooter>
                    </Card>
                </Col>
                <Modal centered={true} isOpen={appointmentModalData.status} toggle={toggleAppointmentModal}>
					<ModalHeader toggle={toggleAppointmentModal}>
						<span>New Appointment Details</span>
					</ModalHeader>
					<ModalBody>
						<form onSubmit={handleMakeAppointmentSubmit}>
							<input type='hidden' value={authUser?.id} name='user_id' />
							<div className='mb-2'>
								<Label htmlFor='prof_user_id' className="form-label">Professional</Label>
								<Input className='form-control' name='prof_user_id' type='hidden' value={profileData?.user?.id}/>
								<Input className='form-control' disabled type='text' value={profileData?.name?.toUpperCase()}/>
							</div>
							<Row className='mb-2'>
								<Col xs={6}>
									<div>
										<Label htmlFor='title' className="form-label">Title</Label>
										<input type="text" className='form-control' name='title' id='title' />
									</div>
								</Col>
								<Col xs={6}>
									<div>
										<Label htmlFor='date_of_apointment' className="form-label">Date of Apointment</Label>
										<input 
											defaultValue={appointmentModalData?.data?.date} 
											type="date" 
											className='form-control' 
											name='date_of_apointment' 
											id='date_of_apointment' 
										/>
									</div>
								</Col>
							</Row>
							<div className="mb-3">
								<Label htmlFor='description' className="form-label">Appointment Descriptions</Label>
								<textarea className='form-control' name='description' id='description' rows={5}></textarea>
							</div>
							<div className="mb-3 center">
								<Button type='submit' className='form-control bg-primary' >Add</Button>
							</div>
						</form>
					</ModalBody>
				</Modal>
            </Row>
        </React.Fragment>
    )
}
