import React from 'react';
import { Container, Row, Col, Card, CardTitle, CardSubtitle, CardText, Button } from 'reactstrap';
// import Carousel from 'react-elastic-carousel';
import data from './data';

export const Event = () => {
  const [sports, setSports] = React.useState(data);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 }
  ];

  const cardStyle = {
    width: '18rem',
    height: '20rem',
    margin: '10px'
  };

  const customArrow = ({ type, onClick }) => <></>;

  const cards = sports.map(item => (
    <div key={item.title} className="d-flex">
      <Card style={cardStyle}>
        <div className="card-body d-flex flex-column justify-content-between">
          <div>
            <CardTitle tag="h5">{item.title}</CardTitle>
            <CardSubtitle tag="h6" className="mb-2 mt-2">{item.startDate}</CardSubtitle>
            <CardText>{item.description}</CardText>
          </div>
          <Button color="primary" style={{ alignSelf: 'flex-end' }}>Accept Invite</Button>
        </div>
      </Card>
    </div>
  ));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              {/* <Carousel breakPoints={breakPoints} renderArrow={customArrow}>
                {cards}
              </Carousel> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

