import React, { useMemo, useState } from 'react'
import { TableResponsive } from 'components/Common/TableResponsive'
import { useModalHandler, usePageInitialor } from 'helpers/customHook'
import { Blog_Api } from 'helpers/api_url';
import { Badge, Button } from 'reactstrap';
import { formatDate } from 'helpers/formatDate';
import DeleteModal from 'components/Common/DeleteModal';
import {NavigationViewButton} from 'components/Common/NavigationButton';

const List = () => {
    const {tableData,apiHandler}=usePageInitialor(Blog_Api);
    const {status,toggleModal}=useModalHandler();
    const [deldata,setDelData]=useState(null);
    const handleBtnDelete=(data)=>apiHandler.handleDelete({id:data.id});
    const columns = useMemo(()=>[
        {Header: "SrNo",accessor: "id", Cell: cell => {return (<p className="">{cell.row.index+1}</p>)},HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Name",accessor: "blogable.first_name",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Category",accessor: "blogcategory.name",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Title",accessor: "title",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Description",accessor: "content",HeaderClass:'text-center', DataClass:'text-center'},
        {
            Header: "Date",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell)=> {
                const row=cell.row.original;
                return (<span className="">{formatDate(row.created_at)}</span>)
            }
        },
        {
            Header: "Status",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell)=> {
                const row=cell.row.original;
                switch (row.approved) {
                    case "1":
                        return <Badge className="bg-success">Approved</Badge>
                    default:
                        return <Badge className="bg-danger">Reject</Badge>
                }
            }
        },
        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
                const row=cell.row.original;
              return ( 
                <div className="">
                    {/* <NavigationViewButton path="/viewblog" data={row} /> */}
                    <Button onClick={(e)=>{e.preventDefault();toggleModal();setDelData(row)}} className="btn btn-sm btn-soft-danger me-1" ><i className="mdi mdi-delete-outline" /></Button>
                </div>
              )
            },
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <a href="#">{row.blogable.first_name}</a> |
                            <span>{row.blogcategory.name} </span>    
                        </h5>
                        <p className="text-muted mb-0">
                        <span>{row.title}</span> | <Badge color="success" outline className="p-2 ms-1">{formatDate(row.created_at)} </Badge> </p>
                    </div>
                    <div className="flex-shrink-0">
                        <div>
                        <NavigationViewButton path="/viewblog" data={row} />
                        <Button onClick={()=>{toggleModal();setDelData(row)}} className="btn btn-sm btn-soft-danger me-1" ><i className="mdi mdi-delete-outline" /></Button>
                        </div>
                    </div>
                </div>
                )
            }
        }
    ]);
  return (
    <React.Fragment>
        <DeleteModal show={status}Text={deldata&&deldata.blogable.first_name}onDeleteClick={()=>{handleBtnDelete(deldata),toggleModal()}}onCloseClick={toggleModal}/>
        <TableResponsive columns={columns} data={tableData} noRecord="No Record List"  />
    </React.Fragment>
  )
}
export default List;