import avatar1 from "assets/images/users/avatar-1.jpg";
import avatar2 from "assets/images/users/avatar-2.jpg";
import avatar3 from "assets/images/users/avatar-3.jpg";
import avatar4 from "assets/images/users/avatar-4.jpg";
import avatar5 from "assets/images/users/avatar-5.jpg";
import avatar6 from "assets/images/users/avatar-6.jpg";
import avatar7 from "assets/images/users/avatar-7.jpg";
import avatar8 from "assets/images/users/avatar-8.jpg";
import adobe from "assets/images/companies/adobe-photoshop.svg";
import adobephotoshop from "assets/images/companies/adobe-photoshop.svg";
import airbnb from "assets/images/companies/airbnb.svg";
import amazon from "assets/images/companies/amazon.svg";
import flutter from "assets/images/companies/flutter.svg";
import mailchimp from "assets/images/companies/mailchimp.svg";
import line from "assets/images/companies/line.svg";
import spotify from "assets/images/companies/spotify.svg";
import wordpress from "assets/images/companies/wordpress.svg";
import upwork from "assets/images/companies/upwork.svg";
import sass from "assets/images/companies/sass.svg";
import reddit from "assets/images/companies/reddit.svg";
const CompanyName = [
    { value: '', label: 'Select company' },
    { value: 1, label: 'ABC company' },
    { value: 2, label: '3ree6ix company' },
    { value: 3, label: 'Abs company' },
    // Add more options as needed
  ];
const Category = [
    { value: '1', label: 'Select Category' },
    { value: 'powerlifting', label: 'powerlifting' },

    // Add more options as needed
  ];
  const JobType = [
    // { value: '', label: 'Select Type' },
    { value: 'Full Time', label: 'Full Time' },
    { value: 'Part Time', label: 'Part Time' },
    { value: 'Internship', label: 'Internship' },
    { value: 'Freelance', label: 'Freelance' },
    // Add more options as needed
  ];
  const JobShare = [
    // { value: '', label: 'select share Job' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'FW Game', label: 'FW Game' },
    { value: 'FW Sport', label: 'FW Sport' },
    { value: 'Google', label: 'Google' },
    
    // Add more options as needed
  ]; 
  const options = [{ value: "Freelance", label: "Freelance" }, { value: "Full Time", label: "Full Time" }, { value: "Part Time", label: "Part Time" }, { value: "Internship", label: "Internship" },]
  const experienceData = [{ id: 1, label: "All", value: "option1" }, { id: 2, label: "Fresher", value: "option1" }, { id: 3, label: "1-2", value: "option2" }, { id: 4, label: "2-3", value: "option2" }, { id: 5, label: "4+", value: "option3" }]
  const jobType = [{ id: 6, label: "Full Time", value: "option3" }, { id: 7, label: "Part Time", value: "option3" }, { id: 8, label: "Freelance", value: "option3" }, { id: 9, label: "Internship", value: "option3" }]
  const JobComfirtable  = [{ value: '', label: 'select share Job' },{ value: 'WFH (Work From Home)', label: 'WFH (Work From Home)' },{ value: 'WFO (Work from office)', label: 'WFO (Work from office)' },{ value: 'Night Shift WFH', label: 'Night Shift WFH' },{ value: 'Night Shift(WFO)', label: 'Night Shift(WFO)' },]
  const jobCategories1 = [{ id : 1, name : "Accounting & Finance" , category : 52},{ id : 2, name : "Development" , category : 10}, { id : 3, name : "Customer Service" , category : 64},{ id : 4, name : "Marketing" , category : 105},{ id : 5, name : "Human Resource" , category : 142},{ id : 6, name : "Health and Care" , category : "01"},{ id : 7, name : "Project Management" , category : 254},{ id : 8, name : "Automotive Jobs" , category : 79},]; 
  const listData = [
    {
        id: 1,
        img: avatar1,
        name: "Steven Franklin",
        designation: "UI/UX Designer",
        location: "Louisiana",
        experience: "38",
        skills: ["Bootstrap", "HTML", "CSS"],
        type: "Full Time",
    },
    {
        id: 2,
        img: avatar2,
        name: "Dolores Minter",
        designation: "Assistant / Shope Keeper",
        location: "Hong-Kong",
        experience: "25",
        skills: ["Shope", "Assistant"],
        type: "Freelance",
    },
    {
        id: 3,
        img: avatar3,
        name: "Charles Brown",
        designation: "Web Designer",
        location: "Finlande",
        experience: "24",
        skills: ["Bootstrap", "HTML", "SASS"],
        type: "Part Time",
    },
    {
        id: 4,
        img: avatar4,
        name: "Bonnie Harney",
        designation: "Web Developer",
        location: "France",
        experience: "47",
        skills: ["MYSQL", "PHP", "Laravel"],
        type: "Internship",
    },
    {
        id: 5,
        img: avatar5,
        name: "Stephen Hadley",
        designation: "Graphic Designer",
        location: "Danemark",
        experience: "83",
        skills: ["Figma", "Adobe XD", "Sketch"],
        type: "Internship",
    },
    {
        id: 6,
        img: avatar6,
        name: "Henry Wells",
        designation: "Executive, HR Operations",
        location: "Danemark",
        experience: "65",
        skills: ["HR", "Executive", "Professional"],
        type: "Internship",
    },
    {
        id: 7,
        img: avatar7,
        name: "Adam Miller",
        designation: "Education Training",
        location: "Colombie",
        experience: "38",
        skills: ["Teaching", "React", "Training"],
        type: "Full Time",
    },
    {
        id: 8,
        img: avatar8,
        name: "Keith Gonzales",
        designation: "Product Manager",
        location: "Brazil",
        experience: "50",
        skills: ["Manager", "Business", "Product"],
        type: "Freelance",
    },
];
const jobsGridData = [
    {
        id: 1,
        img: adobe,
        title: "Magento Developer",
        location: "California"
    },
    {
        id: 2,
        img: adobephotoshop,
        title: "Product Designer",
        location: "UK"
    },
    {
        id: 3,
        img: airbnb,
        title: "Marketing Director",
        location: "USA"
    },
    {
        id: 4,
        img: amazon,
        title: "Project Manager",
        location: "California"
    },
    {
        id: 5,
        img: flutter,
        title: "HTML Developer",
        location: "Canada"
    },
    {
        id: 6,
        img: mailchimp,
        title: "Business Associate",
        location: "UK"
    },
    {
        id: 7,
        img: line,
        title: "Product Sales Specialist",
        location: "USA"
    },
    {
        id: 8,
        img: spotify,
        title: "Magento Developer",
        location: "Pakistan"
    },
    {
        id: 9,
        img: wordpress,
        title: "Magento Developer",
        location: "India"
    },
    {
        id: 10,
        img: upwork,
        title: "Magento Developer",
        location: "California"
    },
    {
        id: 11,
        img: sass,
        title: "Magento Developer",
        location: "Pakistan"
    },
    {
        id: 12,
        img: reddit,
        title: "Magento Developer",
        location: "India"
    },
];

  export  {CompanyName,JobType,JobShare,JobComfirtable, Category,options,experienceData,jobType,jobCategories1,listData,jobsGridData};