export default [
    {
        title: "Cricket",
        description: "Cricket is a bat-and-ball game played between two teams of 11 players. The field is oval-shaped with a 22-yard (20-meter) pitch in the middle. At each end of the pitch, there are two sets of three sticks, called wickets. On top of each wicket are horizontal pieces called bails.",
        startDate:"Starts- 22nd March"
    },
    {
        title: "Football",
        description: "Football, also called association football or soccer, is a game involving two teams of 11 players who try to maneuver the ball into the other team's goal without using their hands or arms. The team that scores more goals wins.",
        startDate:"Starts- 22nd March"
    },
    {
        title: "Basketball",
        description: "Basketball is a fast-paced team sport where two teams of five players compete to score points by throwing a ball through a hoop 10 feet high, called the basket. ",
        startDate:"Starts- 22nd April"
    },
    {
        title: "Tennis",
        description: "Tennis is a racket sport where players use rackets to hit a ball over a net and into the other side of the court. ",
        startDate:"Starts- 22nd March"
    
    },
    {
        title: "Kho Kho",
        description: "India",
        startDate:"Starts- 22nd March"
    },
    {
        title: "Kho Kho",
        description: "India",
        startDate:"Starts- 22nd March"
    },
    {
        title: "Kho Kho",
        description: "India",
        startDate:"Starts- 22nd March"
    },
    {
        title: "Kho Kho",
        description: "India",
        startDate:"Starts- 22nd March"
    },
    {
        title: "Kho Kho",
        description: "India",
        startDate:"Starts- 22nd March"
    },
]
