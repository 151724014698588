import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader,ModalFooter, Col, Input, Row, Button } from 'reactstrap';
import { exercise, schedule, users } from 'helpers/api_url';
import ReactSelect from 'react-select';

function SearchUserModal(prop) {
    const {setSelectedUser} = prop;
    const [status,setStatus] = useState(false);
    const toggleModal = ()=> setStatus(!status);
    const [inputValue, setInputValue] = useState('');
    const [fetchedUsers,setFetchedUsers] = useState([]);
    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };
    const debounceSearch = useCallback(debounce(e=>users.search(e).then(res=>setFetchedUsers(res.data.users)).catch(e=>console.error(e)),300),[])
    const handleInputChange = (e) => {
        setInputValue(e);
        if(e.length > 2)
        debounceSearch(e)
    };
    const handleSubmit = e =>{
        e.preventDefault();
        const formData = new FormData(e.target);
        // users.search(formData).then(res=>{
        //     setCurrentSchedule(res.data.schedule);
        //     toggleModal();
        //     e.target.reset();
        // }).catch(e=>console.error(e));
    }
    return (
        <>
            {prop.children?<span onClick={toggleModal}>{prop.children}</span>:(
                <button onClick={()=>toggleModal()} className="btn btn-sm btn-info ms-2"><i className="mdi mdi-plus"></i></button>
            )}
            <Modal isOpen={status} toggle={() => toggleModal()}>
                <ModalHeader toggle={() => toggleModal()}>Search User</ModalHeader>
                <form onSubmit={e=>handleSubmit(e)}>
                    <ModalBody>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-2">
                                    <label className="control-label">Select User</label>
                                    <ReactSelect 
                                        name='users' 
                                        onInputChange={handleInputChange}
                                        inputValue={inputValue}
                                        isClearable 
                                        options={fetchedUsers?.map(user=>({value:user.id,label:user.first_name + " " + user.last_name}))}/>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter><Button color="primary" type="submit" className="mt-2"> Add </Button></ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default SearchUserModal