import React, { useState } from 'react';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter } from 'reactstrap';

function BmiBmrBac() {
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('male');
    const [bmi, setBMI] = useState(null);
    const [bmr, setBMR] = useState(null);
    const [bac, setBAC] = useState(null);

    const calculateBMI = () => {
        const heightInMeters = height / 100;
        const bmiResult = (weight / (heightInMeters * heightInMeters)).toFixed(2);
        if (!isNaN(bmiResult)) setBMI(bmiResult);
    };

    const calculateKatch = () => {
        if (weight !== '' && height !== '' && age !== '') {
            // Katch-McArdle formula (accounts for lean body mass)
            const leanBodyMass = weight * (1 - 0.01 * 25); // Assuming 25% body fat, replace with your actual body fat value
            const bmrResult = 370 + 21.6 * leanBodyMass;
            setBMR(bmrResult.toFixed(2));
        }
    };

    const calculateMifflin = () => {
        if (weight !== '' && height !== '' && age !== '') {
            // Mifflin St Jeor formula
            const bmrResult =
                gender === 'male'
                    ? 10 * weight + 6.25 * height - 5 * age + 5
                    : 10 * weight + 6.25 * height - 5 * age - 161;
            setBMR(bmrResult.toFixed(2));
        }
    };

    const calculateBAC = () => {
        // Simple BAC formula (using a constant factor)
        if (weight !== '' && height !== '') {
            const bacResult = weight > 0 ? 0.055 * weight : 0;
            setBAC(bacResult.toFixed(2));
        }
    };

    return (
        <React.Fragment>
                    <Breadcrumbs title="Fitness Calculator" breadcrumbItem="Dashboard" />
                    <Row>
                        <Col lg={4}>
                            <Card>
                                <CardHeader>
                                    <CardTitle>
                                        <h3>Results</h3>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ul>
                                        <li>
                                            <Row>
                                                <Col>
                                                    <h4>BMI</h4>
                                                </Col>
                                                <Col>
                                                    <h4>{bmi !== null ? bmi : '-'}</h4>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h4>BMR</h4>
                                                </Col>
                                                <Col>
                                                    <h4>{bmr !== null ? bmr : '-'}</h4>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h4>BAC</h4>
                                                </Col>
                                                <Col>
                                                    <h4>{bac !== null ? bac : '-'}</h4>
                                                </Col>
                                            </Row>
                                        </li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={8}>
                            <Card>
                                <CardHeader>
                                    <CardTitle>
                                        <h3>BMI/BMR/BAC Calculator</h3>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <Row>
                                            <Col lg={6} sm={12}>
                                                <label className="label-control">Weight (kg):</label>
                                                <input
                                                    type="number"
                                                    value={weight}
                                                    className="form-control"
                                                    onChange={(e) => setWeight(e.target.value)}
                                                />
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <label className="label-control">Height (cm):</label>
                                                <input
                                                    type="number"
                                                    value={height}
                                                    className="form-control"
                                                    onChange={(e) => setHeight(e.target.value)}
                                                />
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <label className="label-control">Age :</label>
                                                <input
                                                    type="number"
                                                    value={age}
                                                    className="form-control"
                                                    onChange={(e) => setAge(e.target.value)}
                                                />
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <label className="label-control">Gender :</label>
                                                <select
                                                    value={gender}
                                                    className="form-control"
                                                    onChange={(e) => setGender(e.target.value)}
                                                >
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                </select>
                                            </Col>
                                        </Row>
                                    </form>
                                    <div>
                                        <p>Micro calculator Monday 19th February - 2024, Time 18:18:08</p>
                                        <p>Macro Calculator</p>
                                        <p>Fitness Warrior</p>
                                        <p>
                                            I can help with that. Please provide me with some information so that
                                            I can calculate your suggested macronutrient and calorie needs.
                                        </p>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <div className="d-flex justify-content-end py-2">
                                        <button className="me-2 btn btn-success" onClick={calculateBMI}>
                                            Calculate BMI
                                        </button>
                                        <button className="me-2 btn btn-info" onClick={calculateKatch}>
                                            Calculate BMR - Katch McArdle
                                        </button>
                                        <button className="me-2 btn btn-info" onClick={calculateMifflin}>
                                            Calculate BMR - Mifflin St Jeor
                                        </button>
                                        <button className="me-2 btn btn-primary" onClick={calculateBAC}>
                                            Calculate BAC
                                        </button>
                                    </div>
                                </CardFooter>
                                <CardBody>
                                    <h3>What are Macronutrients (Macros)?</h3>
                                    <p>Macronutrients, or macros for short, are nutrients required by the body in large quantities to maintain normal bodily functions and provide energy. The three main macronutrients are: Carbohydrates: Carbohydrates are the primary source of energy for the body. They are found in foods such as bread, pasta, fruits, and vegetables. Proteins: Proteins are essential for growth and repair of tissues in the body, as well as for the production of enzymes and hormones. They are found in foods such as meat, fish, eggs, and beans. Fats: Fats are also a source of energy for the body, and they play a role in the absorption of certain vitamins. They are found in foods such as butter, oils, and nuts.</p>
                                    
                                    <h3>Protein</h3>
                                    <p>Proteins play a crucial role in the human body, serving a variety of functions such as:</p>
                                    <ol>
                                        <li>Structural support: Proteins provide structural support to various tissues and organs in the body, such as muscles, bones, and skin.</li>
                                        <li>Enzymatic reactions: Many enzymes are made up of proteins and play a crucial role in facilitating chemical reactions in the body.</li>
                                        <li>Transport: Some proteins help transport molecules across cell membranes, such as oxygen-carrying hemoglobin in red blood cells.</li>
                                        <li>Hormones: Some proteins, such as insulin, act as hormones that regulate various bodily functions.</li>
                                        <li>Immune function: Antibodies, which are proteins, help the immune system identify and fight off foreign invaders like bacteria and viruses.</li>

                                    </ol>

                                                             <h3>What are Macronutrients (Macros)?</h3>
                                    <p>Macronutrients, or macros for short, are nutrients required by the body in large quantities to maintain normal bodily functions and provide energy. The three main macronutrients are: Carbohydrates: Carbohydrates are the primary source of energy for the body. They are found in foods such as bread, pasta, fruits, and vegetables. Proteins: Proteins are essential for growth and repair of tissues in the body, as well as for the production of enzymes and hormones. They are found in foods such as meat, fish, eggs, and beans. Fats: Fats are also a source of energy for the body, and they play a role in the absorption of certain vitamins. They are found in foods such as butter, oils, and nuts.</p>
                                    <h3>What are Macronutrients (Macros)?</h3>
                                    <p>Macronutrients, or macros for short, are nutrients required by the body in large quantities to maintain normal bodily functions and provide energy. The three main macronutrients are: Carbohydrates: Carbohydrates are the primary source of energy for the body. They are found in foods such as bread, pasta, fruits, and vegetables. Proteins: Proteins are essential for growth and repair of tissues in the body, as well as for the production of enzymes and hormones. They are found in foods such as meat, fish, eggs, and beans. Fats: Fats are also a source of energy for the body, and they play a role in the absorption of certain vitamins. They are found in foods such as butter, oils, and nuts.</p>
                                    <h3>What are Macronutrients (Macros)?</h3>
                                    <p>Macronutrients, or macros for short, are nutrients required by the body in large quantities to maintain normal bodily functions and provide energy. The three main macronutrients are: Carbohydrates: Carbohydrates are the primary source of energy for the body. They are found in foods such as bread, pasta, fruits, and vegetables. Proteins: Proteins are essential for growth and repair of tissues in the body, as well as for the production of enzymes and hormones. They are found in foods such as meat, fish, eggs, and beans. Fats: Fats are also a source of energy for the body, and they play a role in the absorption of certain vitamins. They are found in foods such as butter, oils, and nuts.</p>
                                    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
        </React.Fragment>
    );
}

export default BmiBmrBac;
