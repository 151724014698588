import React from 'react'
import {Row,Col,Form, Label, Input,  } from 'reactstrap'
import Select from 'react-select';
import { Category, CompanyName, JobType, JobShare, JobComfirtable, initialValues } from './common/data';
import { Job } from 'helpers/api_url';
import { usePageInitialor } from 'helpers/customHook';
const JobPost = () => {
    const {apiHandler}=usePageInitialor(Job);
  return (
    <React.Fragment>
        <Form autoComplete="off"  onSubmit={e => apiHandler.handleSubmit(e)}>
            <Row className='g-3 mx-lg-3 mt-2'>
                <div className='col-6'>
                    <Label htmlFor='category'>Category</Label>
                    <Select className="basic-single" classNamePrefix="select" name="category" id="category"options={Category}  />
                </div>
                <div className='col-6'>
                    <Label htmlFor='company_id'>Company Name</Label>
                    <Select className="basic-single" classNamePrefix="select" name="company_id" id="company_id"options={CompanyName}  />
                </div>
                <div className='col-6'>
                    <Label htmlFor='job_title'>Job Title</Label>
                    <Input type="text" name="job_title" id='job_title'  />
                </div>
                <div className='col-6'>
                    <Label htmlFor='experience'>Experience</Label>
                    <Input type="text" name="experience" id='experience'  />
                </div>
                <div className='col-6'>
                    <Label htmlFor='location'>Location</Label>
                    <Input type="text" name="location" id='location'  />
                </div>
                <div className='col-6'>
                    <Label htmlFor='position'>Position</Label>
                    <Input type="text" name="position" id='position'  />
                </div>
                <div className='col-6'>
                    <Label htmlFor='vacancy'>Vacancy</Label>
                    <Input type="text" name="vacancy" id='vacancy'  />
                </div>
                <div className='col-6'>
                    <Label htmlFor='job_responsibilities'>Job Responsibilities</Label>
                    <textarea name='job_responsibilities' id='job_responsibilities' className='form-control' ></textarea>
                </div>
                <div className='col-6'>
                    <Label htmlFor='job_requirements'>Job Requirements</Label>
                    <textarea name='job_requirements' id='job_requirements' className='form-control' ></textarea>
                </div>
                <div className='col-6'>
                    <Label htmlFor='qualification_required'>Qualification Required</Label>
                    <textarea name='qualification_required' id='qualification_required' className='form-control' ></textarea>
                </div>
                <div className='col-6'>
                    <Label htmlFor='skills_experience'>Skill & Experience</Label>
                    <textarea name='skills_experience' id='skills_experience' className='form-control' ></textarea>
                </div>
                <div className='col-6'>
                    <Label htmlFor='tags'>Tags</Label>
                    <Input type="text" name="tags" id='tags'  />
                </div>
                <div className='col-6'>
                    <Label htmlFor='share_job'>Share Job</Label>
                    <Select className="basic-single" classNamePrefix="select" name="share_job" id="share_job"options={JobShare}  />
                </div>
                <div className='col-6'>
                    <Label htmlFor='job_comfirtable'>Job Comfirtable</Label>
                    <Select className="basic-single" classNamePrefix="select" name="job_comfirtable" id="job_comfirtable"options={JobComfirtable}  />
                </div>
                <div className='col-6'>
                    <Label htmlFor='job_type'>Type</Label>
                    <Select className="basic-single" classNamePrefix="select" name="job_type" id="job_type"options={JobType}  />
                </div>
                <div className='col-6'>
                    <Label htmlFor='job_package'>Package</Label>
                    <Input type="text" name="job_package" id='job_package'  />
                </div>
                <div className='col-6'>
                    <Label htmlFor='duration_start'>Duration Start</Label>
                    <Input type="date" name="duration_start" id='duration_start'  />
                </div>
                <div className='col-6'>
                    <Label htmlFor='duration_end'>Duration End</Label>
                    <Input type="date" name="duration_end" id='duration_end'  />
                </div>
            </Row>
            <Row className="mt-4 mb-3">
                <Col lg={3} className="mx-auto">
                  <div className="hstack gap-2 justify-content-center">
                      <button type="submit" className="btn btn-primary form-control">Submit</button>
                  </div>
                </Col>
              </Row>
        </Form>
        
 
    </React.Fragment>
  )
}
export default JobPost