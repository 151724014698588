import React,{useEffect, useMemo, useState} from 'react'
import { TableResponsive } from 'components/Common/TableResponsive';
import { Job } from 'helpers/api_url';
import { formatDate } from 'helpers/formatDate';
import { toast } from 'react-toastify';
import { Badge, Row,Button } from 'reactstrap';

const JobList = () => {
    const [tableData,setTableData] = useState([]);
    useEffect(()=>{
        Job.list().then(r=>setTableData(r.data)).catch(err=>toast.error(err.response?err.response.data.message:err.message))
      },[]);

      const handleDelete = (Id,name) =>{
                Job.delete(Id)
                .then(res=>{
                    setTableData([...tableData.filter(course=>course.id!=Id)])
                    toast.success(res.message,{ autoClose: 2000 });
                })
                .catch(err=>{
                    toast.error(err.response ? err.response.data.message : err.message);
                })
    }
    const columns = useMemo(()=>[
        {Header: "SrNo",accessor: "id", Cell: cell => {return (<p className="">{cell.row.index+1}</p>)},HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Job Title",accessor: "job_title",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Company Name",accessor: "company.organization_name",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Location",accessor: "location",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Experience",accessor: "experience",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Package",accessor: "job_package",HeaderClass:'text-center', DataClass:'text-center'},
        {
            Header: "Job Type",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell)=> {
                const row=cell.row.original;
                switch (row.job_type) {
                    case "Full Time":
                        return <Badge className="bg-success">{row.job_type}</Badge>
                    case "Part Time":
                        return <Badge className="bg-info">{row.job_type}</Badge>
                    case "Internship":
                        return <Badge className="bg-danger">{row.job_type}</Badge>
                    default:
                        return <Badge className="">{row.job_type}</Badge>
                }
            }
        },
        {
            Header: "Date",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell)=> {
                const row=cell.row.original;
                return (<Badge color="success" outline className="p-2 ms-1">{formatDate(row.duration_start)} to {formatDate(row.duration_end)} </Badge>)
            }
        },

        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
                const row=cell.row.original;
              return ( 
                <div className="">
                    <Button onClick={()=>{handleDelete(row.id,row.job_title)}} className="btn btn-sm btn-soft-danger me-1" >
                        <i className="mdi mdi-delete-outline" />  
                    </Button>
                </div>
              )
            },
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <a href="#">{row.company.organization_name}</a> |
                            <span>{row.location} </span>    
                        </h5>
                        <p className="text-muted mb-0">
                        <span>{row.job_title}</span> | <Badge color="success" outline className="p-2 ms-1">{formatDate(row.duration_start)} to {formatDate(row.duration_end)} </Badge> </p>
                    </div>
                    <div className="flex-shrink-0">
                        <div>
                            <Button onClick={()=>{handleDelete(row.id,row.job_title)}} className="btn btn-sm btn-soft-danger me-1" data-id="1"> <i className="mdi mdi-delete-outline"></i> </Button>
                        </div>
                    </div>
                </div>
                )
            }
        }
    ]);
  return (
    <React.Fragment>
        <Row className='g-3 mx-lg-3 mt-2'>
            <TableResponsive  columns={columns} data={tableData} noRecord="No Record Job List" />
        </Row>
    </React.Fragment>
  )
}
export default JobList;