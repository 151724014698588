import React from 'react'
import { Container,Card, CardBody, Col, Row } from 'reactstrap';
import { listData } from '../Employeer/common/data';
import { Link } from 'react-router-dom';
import avatar2 from "assets/images/users/avatar-2.jpg";
const ViewResume = () => {
    const activeBtn = (ele) => { if (ele.closest("button").classList.contains("active")) { ele.closest("button").classList.remove("active"); } else { ele.closest("button").classList.add("active"); } }
    return (
        <React.Fragment>
            <Row>
                <Col lg={4} xs={12}>
                    <div className='d-none d-lg-block'>
                        <Row>
                            <Col lg={12} xs={12}>
                                {(listData || []).map((item, key) => (
                                    <Col xl={12} key={key}>
                                        <Card>
                                            <CardBody>
                                                <div className="d-flex align-start mb-3">
                                                    <div className="flex-grow-1">
                                                        <span className={item.type === "Full Time" ? "badge badge-soft-success" : item.type === "Freelance" ? "badge badge-soft-info" : item.type === "Part Time" ? "badge badge-soft-danger" : "badge badge-soft-warning"}>
                                                            {item.type}
                                                        </span>
                                                    </div>
                                                    <button type="button" className="btn btn-light btn-sm like-btn" onClick={(e) => activeBtn(e.target)}>
                                                        <i className="bx bx-heart"></i>
                                                    </button>
                                                </div>
                                                <div className="text-center mb-3">
                                                    <img src={item.img} alt="" className="avatar-sm rounded-circle" />
                                                    <h6 className="font-size-15 mt-3 mb-1">{item.name}</h6>
                                                    <p className="mb-0 text-muted">{item.designation}</p>
                                                </div>
                                                <div className="d-flex mb-3 justify-content-center gap-2 text-muted">
                                                    <div>
                                                        <i className='bx bx-map align-middle text-primary'></i> {item.location}
                                                    </div>
                                                    <p className="mb-0 text-center"><i className='bx bx-money align-middle text-primary'></i> ${item.experience} / hrs</p>
                                                </div>
                                                <div className="hstack gap-2 justify-content-center">
                                                    {(item.skills || []).map((subItem, key) => (
                                                        <span key={key} className="badge badge-soft-secondary">{subItem}</span>
                                                    ))}
                                                </div>
                                                <div className="mt-4 pt-1">
                                                    <Link to="/profile" className="btn btn-soft-primary d-block">View Profile</Link>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col lg={8} xs={12}>
                    <Row>
                        <Col lg={4} xs={12}>
                            <Card>
                                <CardBody>
                                    <Row className='mb-2'>
                                        <div className=' text-center'>
                                            <div className="avatar-group-item ">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={avatar2} alt="" className="avatar-md rounded-circle" />
                                                </Link>
                                            </div>
                                            <h4>Amit kumar </h4>
                                        </div>
                                    </Row>
                                    <div className='text-end mt-2'>
                                        <button type="button" className="btn btn-primary btn-sm ">Download CV <i className='bx bx-download  ms-1'></i></button>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={12} lg={10} className='text-start'>Personal Infomation</Col>
                                        <Col xs={12} lg={2} className='text-end'>
                                            <button className="btn btn-sm btn-primary btn-end"><i className="bx bx-pencil align-bottom"></i> </button>
                                        </Col>
                                    </Row>
                                    <hr />

                                    <ul className="list-group">
                                        <li className=''>
                                            <div className="d-flex">
                                                <i className='bx bx-phone-call font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Mobile:</h6>
                                                    <span className="text-muted">+918205644543</span>
                                                </div>
                                            </div>

                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-phone-call font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Whatsapp:</h6>
                                                    <span className="text-muted">+918205644543</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-mail-send font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Email:</h6>
                                                    <span className="text-muted">shubham@gmail.com</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-calendar font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">DOB:</h6>
                                                    <span className="text-muted">16-08-1963</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-user font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Gender:</h6>
                                                    Male
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-vertical-center font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Height:</h6>
                                                    <span className="text-muted">180 cm</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-horizontal-center font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Weight:</h6>
                                                    <span className="text-muted">52 kg</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-user-circle font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Adhar:</h6>
                                                    <span className="text-muted">2345367629</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-user-circle font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">PAN:</h6>
                                                    <span className="text-muted">E32PN6</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={12} lg={10} className='text-start'>Address Details</Col>
                                        <Col xs={12} lg={2} className='text-end'>
                                            <button className="btn btn-sm btn-primary btn-end"><i className="bx bx-pencil align-bottom"></i> </button>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <ul className="list-unstyled vstack gap-3 mb-0">
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-radio-circle-marked font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Address:</h6>
                                                    <span className="text-primary">324,jhushi , prayagraj</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-radio-circle-marked font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">city:</h6>
                                                    <span className="text-primary">prayagraj</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-map-pin font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Pin Code:</h6>
                                                    <span className="text-primary">221305</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={12} lg={10} className='text-start'>Account Details</Col>
                                        <Col xs={12} lg={2} className='text-end'>
                                            <button className="btn btn-sm btn-primary btn-end"><i className="bx bx-pencil align-bottom"></i> </button>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <ul className="list-unstyled vstack gap-3 mb-0">
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-radio-circle-marked font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Bank Name:</h6>
                                                    <span className="text-primary">SBI Bank</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-radio-circle-marked font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">IFSC code:</h6>
                                                    <span className="text-primary">S1BI043</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-money font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Account Number:</h6>
                                                    <span className="text-primary">2213059842432</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-map-pin font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Branch:</h6>
                                                    <span className="text-primary">Jhushi</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bxl-paypal font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">UPI ID:</h6>
                                                    <span className="text-primary">shubham@SBI.Gpay</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={12} lg={12} className='text-start'>Social Media</Col>
                                    </Row>
                                    <hr />
                                    <ul className="list-unstyled vstack gap-3 mb-0">
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bxl-instagram-alt font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Instagram:</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bxl-facebook font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Facebook:</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bxl-twitter font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Twitter:</h6>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={8} xs={12}>
                            <Card>
                                <CardBody>
                                    <h5 className="mb-3">About Us</h5>
                                    <p className="text-muted">Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. Some quick example text to build on the card title and bulk the card's content Moltin gives you platform.</p>
                                    <p className="text-muted mb-4">As a highly skilled and successfull product development and design specialist with more than 4 Years of My experience lies in successfully conceptualizing, designing, and modifying consumer products specific to interior design and home furnishings.</p>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h5 className="mb-3">Education</h5>
                                    <ul className="verti-timeline list-unstyled">
                                        <li className="event-list">
                                            <div className="event-timeline-dot">
                                                <i className="bx bx-right-arrow-circle"></i>
                                            </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <h6 className="font-size-14 mb-1">BCA - Bachelor of Computer Applications</h6>
                                                        <p className="text-muted">International University - (2004-2010)</p>

                                                        <p className="text-muted mb-0">There are many variations of passages of available, but the majority alteration in some form. As a highly skilled and successfull product development and design specialist with more than 4 Years of My experience.</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="event-list">
                                            <div className="event-timeline-dot">
                                                <i className="bx bx-right-arrow-circle"></i>
                                            </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <h6 className="font-size-14 mb-1">MCA - Master of Computer Application</h6>
                                                        <p className="text-muted">International University - (2010-2012)</p>

                                                        <p className="text-muted mb-0">There are many variations of passages of available, but the majority alteration in some form. As a highly skilled and successfull product development and design specialist with more than 4 Years of My experience.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="event-list">
                                            <div className="event-timeline-dot">
                                                <i className="bx bx-right-arrow-circle"></i>
                                            </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <h6 className="font-size-14 mb-1">Design Communication Visual</h6>
                                                        <p className="text-muted">International University - (2012-2015)</p>

                                                        <p className="text-muted mb-0">There are many variations of passages of available, but the majority alteration in some form. As a highly skilled and successfull product development and design specialist with more than 4 Years of My experience.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody className="border-bottom">
                                    <h5 className="mb-3">Key Skill</h5>
                                    <div className="hstack gap-2">
                                        <p className="text-muted mb-0">There are many variations of passages of available, but the majority alteration in some form. As a highly skilled and successfull product development and design specialist with more than 4 Years of My experience.</p>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody className="border-bottom">
                                    <h5 className="mb-3">Other Details</h5>
                                    <div className='row mb-2'>
                                        <div className='col-lg-6'>
                                            <span className='btn btn-sm btn-outline-info'>Current Earning : 5,00,000 Annually</span>
                                        </div>
                                        <div className='col-lg-6'>
                                            <span className='btn btn-sm btn-outline-danger'>Expecting Earning : 6,00,000 Annually</span>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-lg-6'>
                                            <span className='btn btn-sm btn-outline-info'>Comfirtable: Work Form Home</span>
                                        </div>
                                        <div className='col-lg-6'>
                                            <span className='btn btn-sm btn-outline-danger'>Notice Period : 1 week</span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}
export default ViewResume;