import React, { useState } from 'react'
import Breadcrumbs from 'components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter } from "reactstrap";
import American from '../../../assets/images/calculator/American.png'
import John from '../../../assets/images/calculator/John.png'
import Bmi from '../../../assets/images/calculator/bmi.png'

function BodyFatCalculator() {
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('male');
    const [waist, setWaist] = useState('');
    const [wrist, setWrist] = useState('');
    const [hip, setHip] = useState('');
    const [forearm, setForearm] = useState('');
    const [bodyFat, setBodyFat] = useState(null);

    const calculateBodyFat = () => {
        // Jackson-Pollock 3-Site Caliper Method formula for body fat percentage
        let bodyFatResult;
        const sum = parseFloat(waist) + parseFloat(wrist) + parseFloat(hip);
        const density =
          gender === 'male'
            ? 0.29288 - 0.0005 * sum + 0.0000005 * sum * sum - 0.000709 * parseFloat(age)
            : 0.29669 - 0.00043 * sum + 0.00000041 * sum * sum - 0.000917 * parseFloat(age);
        bodyFatResult = (495 / density - 450).toFixed(2);
        setBodyFat(bodyFatResult);
      };
    

    return (
        <React.Fragment>
                    <Breadcrumbs title="Fitness Calculator" breadcrumbItem="Dashboard" />
                    <Row>
                        <Col lg={4}>
                            <Card>
                                <CardHeader><h3>Results</h3></CardHeader>
                                <CardBody>
                                    <ul>
                                        <li>
                                           <Row>
                                                <Col><h4>Body Fat</h4> </Col>
                                                <Col><h4>{bodyFat  !== null ? bodyFat :"-"}</h4> </Col>
                                            </Row> 
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={8}>
                            <Card>
                                <CardHeader>
                                    <CardTitle>
                                        <h3>Body Fat Calculator</h3>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <Row>
                                            <Col lg={6} sm={12} className='mt-4'>
                                                <label className='label-control'>Age :</label>
                                                <input type="number" value={age} className='form-control' onChange={(e) => setAge(e.target.value)} />
                                            </Col>
                                            <Col lg={6} sm={12} className='mt-4'>
                                                <label className='label-control'>Gender :</label>
                                                <select value={gender} className='form-control' onChange={(e) => setGender(e.target.value)}>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                </select>
                                            </Col>
                                            <Col lg={4} sm={12} className='mt-4' >
                                                <label className='label-control'>Waist Circumference (inches) :</label>
                                                <input type="number" className='form-control' value={waist} onChange={(e) => setWaist(e.target.value)} />
                                            </Col>
                                            <Col lg={4} sm={12} className='mt-4' >
                                                <label className='label-control'>Wrist Circumference (inches):</label>
                                                <input type="number" className='form-control' value={wrist} onChange={(e) => setWrist(e.target.value)} />
                                            </Col>
                                            <Col lg={4} sm={12} className='mt-4' >
                                                <label className='label-control'>Hip Circumference (inches) :</label>
                                                <input type="number" className='form-control' value={hip} onChange={(e) => setHip(e.target.value)} />
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                                <CardFooter>
                                    <div className='d-flex justify-content-end py-2'>
                                        <button className='me-2 btn btn-success' onClick={calculateBodyFat}>Calculate Body Fat</button>
                                    </div>
                                </CardFooter>

                                <CardBody>
                                    <Card>
                                        <h3>The American Council on Exercise Body Fat Categorization</h3>
                                        <img src={American}/>
                                    </Card>
                                    <Card></Card>
                                    <Card>
                                        <h3>Jackson & Pollard Ideal Body Fat Percentages</h3>
                                        <img src={John}/>
                                    </Card>
                                    <Card></Card>
                                    <Card>
                                        <h3>Body Fat, Overweight, and Obesity</h3>
                                        <ul>
                                            <li>Body fat is scientifically referred to as "adipose tissue" and serves various important functions in the body.</li>
                                            <li>It includes essential body fat, which is a base level of fat necessary for maintaining life and reproductive functions, and storage body fat, which accumulates in adipose tissue and can have negative health implications in excess.</li>
                                            <li>Men and women have different levels of essential body fat, with men typically having 2-5% and women having 10-13%. The healthy range of body fat for men is 8-19%, and for women, it is 21-33%.</li>
                                            <li>Excess body fat can lead to being overweight and eventually to obesity, but being overweight does not necessarily indicate excess body fat, as body weight includes multiple factors such as muscle, bone density, and water content.</li>
                                            <li>The rate at which body fat accumulates varies from person to person and depends on genetic and behavioral factors such as exercise and diet.</li>
                                            <li>Women and men store body fat differently, and this can change over time, especially for women after menopause and for men after the age of 40, leading to excess body fat around different body areas.</li>
                                        </ul>
                                    </Card>
                                    <Card></Card>
                                    <Card>
                                        <h3>Possible ramifications of excess body fat</h3>
                                        <ul>
                                            <li>The World Health Organization (WHO) classifies obesity as one of the leading preventable causes of death worldwide that is estimated to claim 111,909 to 365,000 deaths per year in the U.S.</li>
                                            <li>Obesity is associated with a reduction in quality of life, poorer mental health outcomes, obstructive sleep apnea, as well as multiple leading causes of death worldwide such as cardiovascular disease, stroke, certain cancers and diabetes. All of these potential complications have the ability to reduce a person's life expectancy, and as such, obesity is a medical condition that is studied by many researchers.</li>
                                            <li>As previously mentioned, fat produces a number of essential hormones that affect a person's body. An excess or a lack of critical hormones can have negative effects that preclude proper body function.</li>
                                            <li>On a related note, studies have found that excess body fat, particularly abdominal fat, disrupts the normal balance and function of some of these hormones. Furthermore, body fat, specifically visceral fat, has a role in the release of specific cytokines, which are a broad category of proteins involved in cell signaling, that can potentially increase the risk of cardiovascular disease.</li>
                                            <li>Visceral fat is also directly associated with higher levels of low-density lipoprotein (LDL) cholesterol, lower high-density lipoprotein (HDL) cholesterol, and insulin resistance.</li>
                                            <li>LDL cholesterol is commonly referred to as "bad cholesterol" while HDL is referred to as "good cholesterol." High levels of LDL cholesterol can clog arteries and lead to complications including heart attacks.</li>
                                            <li>Insulin resistance involves cells not properly responding to the hormone insulin, which can lead to high blood sugar levels, and eventually to type 2 diabetes.</li>
                                            <li>As can be seen, excess visceral fat can have measurable negative impacts to a person's health.</li>

                                        </ul>
                                    </Card>
                                    <Card></Card>
                                    <Card>
                                        <h3>Measuring Body Fat Percentage</h3>
                                        <p>There are many specific techniques used for measuring body fat. 
                                            The calculator above uses a method involving equations developed at the Naval Health Research Center by Hodgdon and Beckett in 1984. The method for measuring the relevant body parts as well as the specific equations used are provided below:</p>
                                        <ul>
                                            <li>Measure the circumference of the subject's waist at a horizontal level around the navel for men, and at the level with the smallest width for women. Ensure that the subject does not pull their stomach inwards to obtain accurate measurements.</li>
                                            <li>Measure the circumference of the subject's neck starting below the larynx, with the tape sloping downward to the front. The subject should avoid flaring their neck outwards.</li>
                                            <li>For women only: Measure the circumference of the subject's hips at the largest horizontal measure.</li>
                                        </ul>
                                        <p>Once these measurements are obtained, use the following formulas to calculate an estimate of body fat. Two equations are provided, one using the U.S. customary system (USC), which uses inches, and the other using the International System of Units, specifically the unit of centimeters.</p>
                                        
                                        <strong>USC Units:</strong>
                                        <p>- BFP = 86.010×log10(abdomen-neck) - 70.041×log10(height) + 36.76</p>
                                        
                                        <p>Fraction = 495 / 1.0324 - 0.19077×log10(waist-neck) + 0.15456×log10(height) = 5</p>
                                        
                                        <strong>Fat mass (FM) formula:</strong>
                                        <p>* FM = BF × Weight</p>

                                        <strong>Lean Mass (LM) formula:</strong>
                                        <p>* LM = Weight - FM</p>

                                        <strong>BMI Method:</strong>
                                        <p>There is a method for estimating body fat percentage using BMI. BMI is calculated by using formulas that require measurements of a person's height and weight. The BMI Calculator can be used to obtain an estimate of BMI, as well as to learn more about how BMI is calculated, what it means, and what its limitations are. Once you have the person's BMI, you can use certain formulas to estimate their body fat percentage.</p>
                                        <img src={Bmi}/>
                                        
                                    </Card>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
        </React.Fragment>
    )
}

export default BodyFatCalculator