import React, { useState } from 'react'

function Pills({text='pill'}) {
    const [selected,setSelected] = useState(false)
    const toggleStateChange = () => setSelected(!selected);
    return (
        <div 
            onClick={()=>toggleStateChange()}
            className={selected?'d-inline-flex justify-content-between bg-primary text-white align-items-center border border-secondary px-3 py-2 m-2':'d-inline-flex justify-content-between align-items-center border border-secondary px-3 py-2 m-2'} 
            style={{borderRadius:'24px',cursor:'pointer'}}>
            <span className='me-2'>{text}</span>
            {!selected?<i className='bx bx-check-double'></i>:<i className='bx bx-x'></i>}
        </div>
    )
}

export default Pills