import React from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import adobephotoshop from "assets/images/companies/adobe.svg";
import wechat from "assets/images/companies/wechat.svg";
import { Link } from 'react-router-dom';
export const JobDetails = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={3} xs={12}>
                            <Card>
                                <CardBody>
                                    <h5 className="fw-semibold">Job overview</h5>
                                    <ul className="list-group">
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'>Job Title</span> </Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}> <span className='text-wrap'> React Developer </span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'>Experience</span> </Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}> <span className='text-nowrap'> 3 year's </span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'>Vacancy</span> </Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}> <span className='text-nowrap'> 3  </span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'>Job Type</span> </Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}> <span className='text-nowrap'> <span className='badge badge-soft-success px-1 ms-1'>Part Time</span> </span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'>Posted Date</span> </Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}> <span className='text-nowrap'> 2024-02-15 </span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'>Close Date</span> </Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}> 2024-02-15 </Col>
                                            </Row>
                                        </li>
                                    </ul>
                                    <div className='w-100 d-flex align-items-center justify-content-between px-2 mt-1'>
                                        <Button className="btn btn-soft-primary w-100 mx-1" >Apply Now </Button>
                                        <Button className="btn btn-soft-danger w-100 mx-1" >Contact Us </Button>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div className="text-center mb-3">
                                        <img src={adobephotoshop} alt="" height="50" className="mx-auto d-block" />
                                        <h5 className="mt-3 mb-1">3ree6ix Company</h5>
                                        <p className="text-muted mb-0">Since July 2024</p>
                                    </div>
                                    <ul className="list-group">
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'><i className='bx bx-phone-outgoing text-primary' /> Contact </span> </Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}><span className='text-nowrap'>+91 7399493628</span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'><i className='bx bx-mail-send text-primary' /> Email </span> </Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}><span className='text-nowrap'>xyzx@gmail.com</span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'><i className='bx bx-globe text-primary' />Website </span> </Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}><span className='text-nowrap'>fittestwarrior.com</span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'><i className='bx bx-map text-primary' />Loaction </span> </Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}><span className='text-nowrap'>Delhi</span></Col>
                                            </Row>
                                        </li>

                                    </ul>
                                    <div className='w-100 d-flex align-items-center justify-content-between px-2 mt-1'>
                                        <Button className="btn btn-soft-primary w-100 mx-1" ><i className="mdi mdi-eye"></i> View Profile </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={9} xs={12}>
                            <Card>
                                <CardBody className='border-bottom'>
                                    <div className='d-flex '>
                                        <img src={wechat} alt="" height="50" />
                                        <div className="flex-grow-1 ms-3">
                                            <h5 className="fw-semibold">3ree6ix Adver and Mark pvt. Ltd.</h5>
                                            <ul className="list-unstyled hstack gap-2 mb-0">
                                                <li><i className="bx bx-building-house"></i> <span className="text-muted">Software Developer</span></li>
                                                <li><i className="bx bx-map"></i> <span className="text-muted">Delhi</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <h5 className="fw-semibold mb-3">About Company</h5>
                                    <p className="text-muted">We are in the business of growing your business, We focus on our clients goals, regardless of the size, scope all type of project.</p>
                                    <h5 className="fw-semibold mb-3">Responsibilities:</h5>
                                    <ul className="vstack gap-3">
                                        <li>
                                            Building and configuring Magento 1x and 2x eCommerce websites.
                                        </li>
                                        <li>
                                            Coding of the Magento templates.
                                        </li>
                                        <li>
                                            Developing Magento modules in PHP using best practices.
                                        </li>
                                        <li>
                                            Designing 3ree6ix and interfaces.
                                        </li>
                                        <li>
                                            Setting performance tasks and goals.
                                        </li>
                                        <li>
                                            Updating website features and security patches.
                                        </li>
                                    </ul>

                                    <h5 className="fw-semibold mb-3">Requirements:</h5>
                                    <ul className="vstack gap-3">
                                        <li>
                                            Advanced knowledge of Magento, JavaScript, HTML, PHP, CSS, and MySQL.
                                        </li>
                                        <li>
                                            Experience with complete eCommerce lifecycle development.
                                        </li>
                                        <li>
                                            Understanding of modern UI/UX trends.
                                        </li>
                                        <li>
                                            Knowledge of Google Tag Manager, SEO, Google Analytics, PPC, and A/B Testing.
                                        </li>
                                        <li>
                                            Good working knowledge of Adobe Photoshop and Adobe Illustrator.
                                        </li>
                                        <li>
                                            Strong attention to detail.
                                        </li>
                                        <li>
                                            Ability to project-manage and work to strict deadlines.
                                        </li>
                                        <li>
                                            Ability to work in a team environment.
                                        </li>
                                    </ul>

                                    <h5 className="fw-semibold mb-3">Qualification:</h5>
                                    <ul className="vstack gap-3">
                                        <li>
                                            3 or more years of professional design experience
                                        </li>
                                        <li>
                                            Advanced degree or equivalent experience in graphic and web design
                                        </li>
                                    </ul>

                                    <h5 className="fw-semibold mb-3">Skill & Experience:</h5>
                                    <ul className="vstack gap-3 mb-0">
                                        <li>
                                            Proficiency With HTML, CSS, Bootstrap
                                        </li>
                                        <li>
                                            WordPress: 1 year (Required)
                                        </li>
                                        <li>
                                            Experience designing and developing responsive design websites
                                        </li>
                                        <li>
                                            web designing: 1 year (Preferred)
                                        </li>
                                    </ul>

                                    <div className="mt-4">
                                        <span className="badge badge-soft-warning me-1">Magento</span>
                                        <span className="badge badge-soft-warning me-1">Marketing</span>
                                        <span className="badge badge-soft-warning me-1">WordPress</span>
                                        <span className="badge badge-soft-warning">Bootstrap</span>
                                    </div>

                                    <div className="mt-4">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item mt-1">
                                                Share this job:
                                            </li>
                                            <li className="list-inline-item mt-1">
                                                <Link to="#" className="btn btn-outline-danger btn-hover"><i className="uil uil-google"></i> Google+</Link>
                                            </li>
                                            <li className="list-inline-item mt-1">
                                                <Link to="#" className="btn btn-outline-success btn-hover"><i className="uil uil-linkedin-alt"></i> linkedin</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
