import React, { useRef, useState } from 'react'
import { Row, Col, Form, Label, Input,Button } from 'reactstrap'
import Select from 'react-select';
import { users } from './common/data';
import { TextEditor } from 'components/Common/TextEditor';
import { usePageInitialor } from 'helpers/customHook';
import { Blog_Api } from 'helpers/api_url';
const CreateForm = () => {
    const textData=useRef();
    const[userprofile,setUserProfile]=useState();
    const {apiHandler}=usePageInitialor(Blog_Api);
    const onSave = f => {setFileInput(f);console.log(f)}
    const setFileInput = (file) => {const dataURL = window.URL.createObjectURL(file);setUserProfile(dataURL);}
    return (
        <React.Fragment>
            <Form onSubmit={e => apiHandler.handleSubmit(e,textData.current)}>
                <Row className='g-3 mx-lg-3 mt-2'>
                    <div className='col-4'>
                        <Label htmlFor='usertype'>User Type</Label>
                        <Select className="basic-single" classNamePrefix="select" name="usertype" id="usertype" options={users} />
                    </div>
                    <div className='col-4'>
                        <Label htmlFor='category'>Category</Label>
                        <Select className="basic-single" classNamePrefix="select" name="category" id="category" options={users} />
                    </div>
                    <div className='col-4'>
                        <Label htmlFor='postby'>Post By</Label>
                        <Select className="basic-single" classNamePrefix="select" name="postby" id="postby" options={users} />
                    </div>
                    <div className='col-6'>
                        <div className='mb-3'>
                        <Label htmlFor='heading'>Title</Label>
                        <Input type="text" name="heading" id='heading' />
                        </div>
                        <div className='mb-3'>
                        <Label htmlFor='image'>Post Image</Label>
                        <Input type="file" name="image" id='image' onChange={(e) => onSave(e.target.files[0])}  />
                        </div>
                    </div>
                    <div className='col-6'>
                        <Label htmlFor='description'>Description</Label>
                        {/* <TextEditor textData={textData} id="description"   /> */}
                        <textarea  name="description" className='form-control' rows={5} />
                    </div>
                    <div className='col-6'>
                        {userprofile ? <img src={userprofile} width="200px" height="200px" /> : ''}
                    </div>
                </Row>
                <Row className="mt-4 mb-3">
                <Col lg={3} className="mx-auto">
                  <div className="hstack gap-2 justify-content-center">
                      <Button type="submit" color='primary' className='px-5'>Submit</Button>
                  </div>
                </Col>
              </Row>
            </Form>
        </React.Fragment>
    )
}
export default CreateForm