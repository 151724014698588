import React, { useEffect, useState } from 'react'
import BreadCrumb from 'components/Common/Breadcrumb';
import { Card, CardBody, Col, Container, Nav, NavItem, Row, TabContent, TabPane } from 'reactstrap';
import { NavLink, useLocation } from 'react-router-dom';
import classnames from 'classnames';
const ViewBlog = () => {
    const location = useLocation();
    const { data } = location.state || {};
    const [viewData, setviewData] = useState([]);
    const [activeTab, toggleTab] = useState("1");
    //   useEffect(()=>{
    //     item.list().then(r => setviewData(r.data[Object.keys(r.data)[0]])).catch(err => swal.error(err.response ? err.response.data.message : err.message))
    //   })
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="View Blog" prevPage="Dashboard" prevPath="/dashboard" />
                    <Row>
                        <Col lg={3} xs={12}>
                            <Card>
                                <CardBody>
                                    <div className="search-box">
                                        <p className="text-muted">Search</p>
                                        <div className="position-relative">
                                            <input type="text" className="form-control rounded bg-light border-light" placeholder="Search..." />
                                            <i className="mdi mdi-magnify search-icon"></i>
                                        </div>
                                    </div>
                                    <hr className="my-4" />

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={9} xs={12}>
                            <Card>
                                <CardBody>
                                <Nav className='justify-content-center pt-2'>
                                    <NavItem><NavLink to="#" className={classnames({'active':activeTab == "1","px-2":true})} onClick={()=>toggleTab("1")}> All Post</NavLink></NavItem>
                                    <NavItem><NavLink to="#" className={classnames({'active':activeTab == "2","px-2":true})} onClick={()=>toggleTab("2")}>Archive</NavLink></NavItem>
                                </Nav>
                                <hr className="mt-0" />
                                <TabContent className="p-2" activeTab={activeTab} >
                                    <TabPane tabId="1">
                                        Helo
                                    </TabPane>
                                    <TabPane tabId="2">
                                        welcome
                                    </TabPane>
                                </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default ViewBlog;