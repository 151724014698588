import { TableResponsive } from 'components/Common/TableResponsive';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, CardTitle, CardHeader, Modal, ModalBody, ModalHeader, Col, Input, Label, Form, Row, Button, UncontrolledTooltip } from 'reactstrap';
// import { Select } from 'react-select';
import ReactSelect from 'react-select';
import { Diet } from 'helpers/api_url';
import { toast } from 'react-toastify';
import { usePageInitialor } from 'helpers/customHook';
import { changePreloader as setPreloader } from 'store/layout/actions'
import { useDispatch } from 'react-redux';
import { ViewFood } from './ViewFood';
import { foodTypeDotGenerator } from 'helpers/badgeGenerator';
// Create Read Update Delete Food 
// food -> name,nutrients, in unit (100 gm / kg etc)
const Food = ({tabNumber}) => {
    const dispatch = useDispatch();
    
    const { tableData, apiHandler, setTableData } = usePageInitialor(Diet);
    
    const handleNutrientDelete = (id) => apiHandler.handleDelete({ id: id });
    const [status, setStatus] = useState(false);
    const toggleModal = () => setStatus(!status);
    const [changeProtienValue, setChangeProtienValue] = useState("");
    const [changeFatValue, setChangeFatValue] = useState("");
    const [changeCarboValue, setChangeCarboValue] = useState("");
    const [totalKcal, setTotalKcal] = useState("");

    const handleRefreshTotalCalorie = () => {
        const totalkcal = (parseFloat(changeProtienValue) || 0) * 4 + (parseFloat(changeCarboValue) || 0) * 4 + (parseFloat(changeFatValue) || 0) * 9;
        setTotalKcal(totalkcal);
    };

    const columns = useMemo(() => [
        { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        {
            Header: "Food Name",
            Cell: (cell) => {
                const row = cell.row.original;
                return (<>
                    <div id={"foodname_"+row.id} >
                        <span style={{ cursor: 'pointer' }} >{row.name}</span> {foodTypeDotGenerator(row.type)}
                    </div>
                    <UncontrolledTooltip placement="top" target={"foodname_"+row.id}>
                        {row.quantity}  {row.unit} | {row.type}
                    </UncontrolledTooltip>
                </>)
            }
        },

        {
            Header: "Protien",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        {row?.nutrients?.filter(n => n.name == 'Protein')[0]?.quantity}
                    </>)
            }
        },
        {
            Header: "Fat",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        {row?.nutrients?.filter(n => n.name == 'FAT')[0]?.quantity}
                    </>)
            }
        },
        {
            Header: "Carb",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        {row?.nutrients?.filter(n => n.name == 'Carbohydrate')[0]?.quantity}
                    </>)
            }
        },
        {
            Header: "Calorie",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        {(parseFloat(row?.nutrients?.filter(n => n.name == 'Protein')[0]?.quantity) * 4) + (parseFloat(row?.nutrients?.filter(n => n.name == 'Carbohydrate')[0]?.quantity) * 4) + (parseFloat(row?.nutrients?.filter(n => n.name == 'FAT')[0]?.quantity) * 9)}
                    </>)
            }
        },

        {
            Header: 'Action',
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: cell => {
                const row = cell.row.original;
                return (
                    <div>
                        <button onClick={() => handleNutrientDelete(row.id)} className="btn btn-sm btn-danger me-1"><i className="mdi mdi-delete-outline"></i></button>
                        <ViewFood food={row} />

                        {/* <button className="btn btn-sm btn-warning me-1"><i className="mdi mdi-pencil-outline"></i></button> */}
                    </div>
                )
            }
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <a href="#">{row.name} </a> | {foodTypeDotGenerator(row.type)}
                        </h5>
                       
                    </div>
                    <div className="flex-shrink-0">
                    <button onClick={() => handleNutrientDelete(row.id)} className="btn btn-sm btn-danger me-1"><i className="mdi mdi-delete-outline"></i></button>
                    <ViewFood food={row} />
                    </div>
                </div>
                )
            }
        }
    ]);

    const [value, setValue] = useState('');
    const [convertedValue, setConvertedValue] = useState(null);
    const [isGramToLiter, setIsGramToLiter] = useState(true); 

    const handleConverter = () => {
        if (isGramToLiter) {
            // Convert grams to liters (1 gram = 0.001 liters)
            const result = (parseFloat(value) * 0.001).toFixed(4);
            setConvertedValue(result + ' L');
        } else {
            // Convert liters to grams (1 liter = 1000 grams)
            const result = (parseFloat(value) * 1000).toFixed(4);
            setConvertedValue(result + ' g');
        }
    };

    const toggleConversion = () => {
        setIsGramToLiter(!isGramToLiter);
        setConvertedValue(null); // Clear the converted value when toggling
        setValue(''); // Clear the input value when toggling
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const foodName = formData.get('name');
        const quantity = formData.get('quantity');
        const foodType = formData.get('food_type');
        const foodUnit = formData.get('food_unit');
        const remark = formData.get('remark');
        const nutrition = [
            {
                name: formData.get(`carbs`),
                value: formData.get(`nutrient_value_carbs`),
                unit: formData.get(`weigt_carbs`),
            },
            {
                name: formData.get(`protein`),
                value: formData.get(`nutrient_value_protein`),
                unit: formData.get(`weigt_protein`),
            },
            {
                name: formData.get(`fat`),
                value: formData.get(`nutrient_value_fat`),
                unit: formData.get(`weigt_fat`),
            },
            {
                name: formData.get(`kcal`),
                value: formData.get(`nutrient_value_kcal`),
                unit: formData.get(`weigt_kcal`),
            },
        ];
        // Construct the form data object
        const foodFormData = { foodName, foodType, quantity, foodUnit, nutrition, remark };
        dispatch(setPreloader({ loader: true, message: 'please wait' }));
        Diet.add(foodFormData)
            .then((res) => {
                setTableData(state => state.filter(i => i.id !== res.data.food.id));
                toast.success(res.message, { autoClose: 2000 });
                e.target.reset();
                toggleModal();
            })
            .catch((err) => {
                toast.error(err.response ? err.response.data.message : err.message);
            })
            .finally(() =>
                dispatch(setPreloader({ loader: false, message: '' }))
            );
    }


    return (
        <>
            <Card>
                <CardHeader className='d-flex justify-content-between'>
                    <CardTitle>Foods</CardTitle>
                    <Button className='bg-primary' onClick={toggleModal}>+ Add New</Button>
                </CardHeader>
                <CardBody>
                    <TableResponsive columns={columns} data={tableData} noRecord="No Record List" />
                </CardBody>
            </Card>
            <Modal isOpen={status} toggle={() => toggleModal()} size="lg">
                <ModalHeader toggle={() => toggleModal()}>Add New Food</ModalHeader>
                <ModalBody>
                    <Card>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col xs={4}></Col>
                                <Col xs={4}></Col>
                                <Col xs={4} className='text-end'>
                                <span className='px-2'>{isGramToLiter ? 'Gram' : 'Liter'}</span>
                                
                                    <Button
                                        onClick={toggleConversion}
                                        className="btn btn-sm btn-soft-success  btn-end"
                                        id="convert">
                                        to<br />
                                        <i className="bx bx-transfer-alt fs-4 mt-0 pt-0" />
                                    </Button>  
                                    <span className='px-2'>{isGramToLiter ? 'Liter' : 'Gram'}</span>
                                    <UncontrolledTooltip placement="top" target="convert">
                                                        convert
                                                    </UncontrolledTooltip>
                                </Col>
                                <Col xs={4} className='mt-2'>
                                    <Label htmlFor='name'>Food Name</Label>
                                    <Input type='text' name='name' id='name' />
                                </Col>
                                <Col xs={4} className='mt-2'>
                                    <Label htmlFor='food_type'>Food Type</Label>
                                    <ReactSelect id={'food_type'} name={'food_type'} options={[
                                        { label: 'Vegan', value: 'Vegan' },
                                        { label: 'vegetrain', value: 'veg' },
                                        { label: 'Egetrain', value: 'egg' },
                                        { label: 'Non-vegetrain', value: 'nonveg' },
                                    ]} />
                                </Col>
                                <Col xs={4} className='mt-2'>
                                    <Label htmlFor='quantity'> </Label>
                                    <div className="input-group mb-3 mt-2">
                                        <Input type='text' name='quantity' id='quantity' />
                                        <input type="hidden" value="g" name="food_unit" />
                                        <span className="input-group-text" id="basic-addon2"> <span className='px-2'>{isGramToLiter ? 'Gram' : 'Liter'}</span></span>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={6} className='mt-2'>
                                            <Label htmlFor=""></Label>
                                            <Input type='text' name="carbs" id="carbs" value="Carbohydrate" />
                                        </Col>
                                        <Col xs={6} className='mt-2'>
                                            <Label htmlFor='quantity'></Label>
                                            <div className="input-group mb-3">
                                                <Input type='text' name='nutrient_value_carbs' id='nutrient_value_carbs' defaultValue={changeCarboValue} onChange={(e) => setChangeCarboValue(e.target.value)} />
                                                <input type="hidden" value="g" name="weigt_carbs" />
                                                <span className="input-group-text" id="basic-addon2"> <span className='px-2'>{isGramToLiter ? 'Gram' : 'Liter'}</span></span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6} className='mt-2'>
                                            <Label htmlFor=""></Label>
                                            <Input type='text' name="protein" id="protein" value="Protein" />
                                        </Col>
                                        <Col xs={6} className='mt-2'>
                                            <Label htmlFor='quantity'></Label>
                                            <div className="input-group mb-3">
                                                <Input type='text' name='nutrient_value_protein' id='nutrient_value_protein' defaultValue={changeProtienValue} onChange={(e) => setChangeProtienValue(e.target.value)} />
                                                <input type="hidden" value="g" name="weigt_protein" />
                                                <span className="input-group-text" id="basic-addon2"> <span className='px-2'>{isGramToLiter ? 'Gram' : 'Liter'}</span></span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6} className='mt-2'>
                                            <Label htmlFor=""></Label>
                                            <Input type='text' name="fat" id="fat" value="FAT" />
                                        </Col>
                                        <Col xs={6} className='mt-2'>
                                            <Label htmlFor='quantity'></Label>
                                            <div className="input-group mb-3">
                                                <Input type='text' name='nutrient_value_fat' id='nutrient_value_fat' defaultValue={changeFatValue} onChange={(e) => setChangeFatValue(e.target.value)} />
                                                <input type="hidden" value="g" name="weigt_fat" />
                                                <span className="input-group-text" id="basic-addon2"> <span className='px-2'>{isGramToLiter ? 'Gram' : 'Liter'}</span></span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6} className='mt-2'>
                                            <Label htmlFor=""></Label>
                                            <Input type='text' name="kcal" id="kcal" value={'Calorie'} />
                                        </Col>
                                        <Col xs={6} className=''>
                                            <div className="d-flex justify-content-end">
                                            </div>
                                            <div className="input-group mb-3 mt-4">
                                                <Input type='text' name='nutrient_value_kcal' id='nutrient_value_kcal' value={totalKcal} />
                                                <input type="hidden" value="g" name="weigt_kcal" />
                                                <span className="input-group-text" id="basic-addon2"> <span className='px-2'>{isGramToLiter ? 'Kcal' : 'L'}</span></span>
                                                <span className="input-group-text" id="basic-addon2">
                                                    <Button
                                                        onClick={() => handleRefreshTotalCalorie()}
                                                        className="btn btn-sm btn-soft-danger me-1 btn-end"
                                                        id="totalkcal">
                                                        <i className="bx bx-rotate-left" />
                                                    </Button>
                                                    <UncontrolledTooltip placement="top" target="totalkcal">
                                                        Refresh then total value calculate
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={3} className='mt-2 text-lg-end'>
                                            Remark
                                        </Col>
                                        <Col xs={6} className='mt-2'>
                                            <textarea name="remark" id="remark" className='form-control' rows="2"></textarea>
                                        </Col>
                                    </Row>
                                </Col>

                                <div className="mt-4 text-center">
                                    <div className="mb-2 d-grid"><button className="btn btn-primary mx-auto" type="submit">submit</button></div>
                                </div>
                            </Row>
                        </Form>
                    </Card>
                </ModalBody>
            </Modal>
        </>
    )
}

export default Food