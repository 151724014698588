import React, { useMemo, useState } from 'react'
import { TableResponsive } from 'components/Common/TableResponsive'
import { useModalHandler, usePageInitialor } from 'helpers/customHook'
import { Blog_Api } from 'helpers/api_url';
import { Row, Col, Badge, Button, Card, CardBody, Container, Form, Modal, ModalBody, ModalHeader, Label, Input } from 'reactstrap';
import { formatDate } from 'helpers/formatDate';
import DeleteModal from 'components/Common/DeleteModal';
import { NavigationViewButton } from 'components/Common/NavigationButton';
import BreadCrumb from 'components/Common/Breadcrumb';

const ManageList = () => {
    const { tableData, apiHandler } = usePageInitialor(Blog_Api);
    const { status, toggleModal } = useModalHandler();
    const [editModal, setEditModal] = useState(false)
    const [deldata, setDelData] = useState(null);
    const handleBtnDelete = (data) => apiHandler.handleDelete({ id: data.id });
    const [isChecked, setIsChecked] = useState(true);
    const handleToggle = () => { setIsChecked(!isChecked); };
    const columns = useMemo(() => [
        { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Name", accessor: "blogable.first_name", HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Category", accessor: "blogcategory.name", HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Title", accessor: "title", HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Description", accessor: "content", HeaderClass: 'text-center', DataClass: 'text-center' },
        {
            Header: "Date",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (<span className="">{formatDate(row.created_at)}</span>)
            }
        },
        {
            Header: "Status",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                switch (row.approved) {
                    case "1":
                        return <Badge className="bg-success">Confirm <i className="bx bx-check-double"></i></Badge>
                    default:
                        return <Badge className="bg-danger">Reject</Badge>
                }
            }
        },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <div className="">
                        {/* <NavigationViewButton path="/viewblog" data={row} /> */}
                        <Button onClick={(e) => { e.preventDefault(); setEditModal(true) }} className="btn btn-sm btn-soft-success me-1" ><i className="bx bxs-check-shield" /></Button>
                        <Button onClick={(e) => { e.preventDefault(); toggleModal(); setDelData(row) }} className="btn btn-sm btn-soft-danger me-1" ><i className="mdi mdi-delete-outline" /></Button>
                    </div>
                )
            },
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                return (
                    <div className="d-flex">
                        <div className="flex-grow-1" data-id="1">
                            <h5 className="fs-13 mb-1">
                                <a href="#" className="link text-dark"></a>
                                <a href="#">{row.blogable.first_name}</a> |
                                <span>{row.blogcategory.name} </span>
                            </h5>
                            <p className="text-muted mb-0">
                                <span>{row.title}</span> | <Badge color="success" outline className="p-2 ms-1">{formatDate(row.created_at)} </Badge> </p>
                        </div>
                        <div className="flex-shrink-0">
                            <div>
                                <NavigationViewButton path="/viewblog" data={row} />
                                <Button onClick={(e) => { e.preventDefault(); setEditModal(true) }} className="btn btn-sm btn-soft-success me-1" ><i className="bx bxs-check-shield" /></Button>
                                <Button onClick={(e) => { e.preventDefault(); toggleModal(); setDelData(row) }} className="btn btn-sm btn-soft-danger me-1" ><i className="mdi mdi-delete-outline" /></Button>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    ]);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Blog Manage" prevPage="Dashboard" prevPath="/dashboard" />
                    <Card>
                        <CardBody>
                            <DeleteModal show={status} Text={deldata && deldata.blogable.first_name} onDeleteClick={() => { handleBtnDelete(deldata), toggleModal() }} onCloseClick={toggleModal} />
                            <TableResponsive columns={columns} data={tableData} noRecord="No Record List" />
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal isOpen={editModal} toggle={() => { setEditModal(); }}>
                <ModalHeader toggle={() => setEditModal(false)}> Aprrove & Reject Manage </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col lg={6} xs={12}>
                                <Label htmlFor='type'>Type</Label>
                                <Input type='text' name='type' id='type' defaultValue={'Admin'} readOnly />
                            </Col>
                            <Col lg={6} xs={12}>
                                <Label htmlFor='name'>Name</Label>
                                <Input type='text' name='name' id='name' defaultValue={'Shivam'} readOnly />
                            </Col>
                            <Col lg={6} xs={12} className='mt-2'>
                                <Label htmlFor='category'>Category</Label>
                                <Input type='text' name='category' id='category' defaultValue={'software'} readOnly />
                            </Col>
                            <Col lg={6} xs={12} className='mt-2'>
                                <Label htmlFor='post'>Post By</Label>
                                <Input type='text' name='final' id='final' defaultValue={'sunil'} readOnly />
                            </Col>
                            <Col lg={12} className='mt-2'>
                                <div className="mb-4">
                                    <div className="form-check form-switch form-check-success">
                                        <input className="form-check-input" type="checkbox" role="switch" id="apro_rej" checked={isChecked} onChange={handleToggle} />
                                        <label className="form-check-label" htmlFor="apro_rej">
                                            {isChecked ? <Badge className="bg-success">Approved <i className="bx bx-check-double"></i></Badge> :
                                                <Badge className="bg-danger">Reject <i className="bx bx-x"></i></Badge>}
                                        </label>
                                    </div>
                                </div>
                            </Col>

                            {isChecked ? '' :
                                <Col lg={12}>
                                    <div className="mb-4">
                                        <Label htmlFor="description">Reason</Label>
                                        <textarea className="form-control" id="description" name="description" rows="3" placeholder="Enter your message" ></textarea>
                                    </div>
                                </Col>
                            }

                            <Col lg={12} className='mt-2'>
                                <div className="text-end">
                                    <button type='button' className="btn btn-success me-1">Save <i className="bx bx-send align-middle"></i></button>
                                    <button className="btn btn-outline-secondary" onClick={(e) => {e.preventDefault();setEditModal(false)}}>Cancel</button>
                                </div>
                            </Col>
                        </Row>

                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}
export default ManageList;