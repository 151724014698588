import React, { useState, useMemo, useEffect } from 'react'
import { Col, Form, Input, Label, Row, Collapse, Card, CardBody, Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
// flatpickr
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
import { experienceData, jobType, jobsGridData } from '../Employeer/common/data';
import { Link } from 'react-router-dom';
import Paginations from 'components/Common/Pagination';
const FindJob = () => {
    const [applyJobmodal, setModal] = useState(false);
    const [advancefilter, setAdvanceFilter] = useState(false);
    const toggle = () => setAdvanceFilter(!advancefilter);
    const activeBtn = (ele) => { if (ele.closest("button").classList.contains("active")) { ele.closest("button").classList.remove("active"); } else { ele.closest("button").classList.add("active"); } }
    // pagination
    const [jobData, setJobData] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const perPageData = 8;
    const indexOfLast = currentPage * perPageData;
    const indexOfFirst = indexOfLast - perPageData;
    const currentdata = useMemo(() => jobsGridData?.slice(indexOfFirst, indexOfLast), [jobsGridData, indexOfFirst, indexOfLast])
    useEffect(() => { setJobData(currentdata); }, [currentdata]);
    return (
        <React.Fragment>
            <Form>
                <Row>
                    <Col xxl={4} lg={4}>
                        <div className="position-relative">
                            <Input type="text" className="form-control" id="searchJob" placeholder="Search your job" />
                        </div>
                    </Col>
                    <Col xxl={2} lg={4}>
                        <div className="position-relative">
                            <Input type="text" className="form-control" id="locationInput" placeholder="Location" />
                        </div>
                    </Col>
                    <Col xxl={2} lg={4}>
                        <div className="position-relative">
                            <Input type="text" className="form-control" id="jobCategories" placeholder="Categories" />
                        </div>
                    </Col>
                    <Col xxl={2} lg={6}>
                        <div className="position-relative">
                            <div id="datepicker1">
                                <FlatPickr className="form-control" placeholder="Select Date" options={{ dateFormat: "d M,Y" }} />
                            </div>
                        </div>
                    </Col>
                    <Col xxl={2} lg={6}>
                        <div className="position-relative h-100 hstack gap-3">
                            <button type="button" className="btn btn-primary "><i className="bx bx-search-alt align-middle"></i> </button>
                            <a href="#" onClick={toggle} className="btn btn-secondary ">
                                <i className="bx bx-filter-alt align-middle"></i> </a>
                        </div>
                    </Col>
                    <Collapse isOpen={advancefilter}>
                        <div>
                            <Row className="g-3 mt-2">
                                <Col xxl={4} lg={6}>
                                    <div>
                                        <Label htmlFor="experience" className="form-label fw-semibold">Experience</Label>
                                    </div>
                                    {
                                        (experienceData || []).map((item, index) => (
                                            <div className="form-check form-check-inline" key={index}>
                                                <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${item.id}`} value={item.value} />
                                                <Label className="form-check-label" htmlFor={`inlineCheckbox${item.id}`}>{item.label}</Label>
                                            </div>
                                        ))
                                    }
                                </Col>
                                <Col xxl={4} lg={6}>
                                    <div>
                                        <Label htmlFor="jobType" className="form-label fw-semibold">Job Type</Label>
                                    </div>
                                    {
                                        (jobType || []).map((item, index) => (
                                            <div className="form-check form-check-inline" key={index}>
                                                <Input type="checkbox" id={`inlineCheckbox${item.id}`} value={item.value} />
                                                <Label className="form-check-label" htmlFor={`inlineCheckbox${item.id}`}>{item.label}</Label>
                                            </div>
                                        ))
                                    }
                                </Col>
                                <Col xxl={4} lg={4}>
                                    <div className="position-relative">
                                        <Label htmlFor="qualificationInput" className="fw-semibold">Qualification</Label>
                                        <Input type="text" id="qualificationInput" autoComplete="off" placeholder="Qualification" />
                                        <i className="ri-government-line filter-icon"></i>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                </Row>
            </Form>
            <hr />

            <Row>
                {(jobData || []).map((item, key) => (
                    <Col xl={4} md={6} key={key}>
                        <Card>
                            <CardBody>
                                <div className="d-flex  mb-3">
                                    <div className="flex-grow-1"><img src={item.img} alt="" height="50" className="mb-3" /></div>
                                    <div className="favorite-icon"><button type="button" className="btn btn-light btn-sm like-btn" onClick={(e) => activeBtn(e.target)}><i className="bx bx-heart"></i></button></div>
                                </div>
                                <h5 className="fs-17 mb-2">
                                    <Link to="/job-details" className="text-dark">{item.title}</Link> <small className="text-muted fw-normal">(0-2 Yrs Exp.)</small></h5>
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item"><p className="text-muted fs-14 mb-1">Skote Technology Pvt.Ltd</p></li>{" "}
                                    <li className="list-inline-item"><p className="text-muted fs-14 mb-0"><i className="mdi mdi-map-marker"></i> {item.location}</p> </li>
                                    <li className="list-inline-item"><p className="text-muted fs-14 mb-0"><i className="uil uil-wallet"></i> $250 - $800 / month</p></li>
                                </ul>
                                <div className="mt-3 hstack gap-2">
                                    <span className="badge rounded-1 badge-soft-success">Full Time</span>
                                    <span className="badge rounded-1 badge-soft-warning">Urgent</span>
                                    <span className="badge rounded-1 badge-soft-info">Private</span>
                                </div>
                                <div className="mt-4 hstack gap-2">
                                    <Link to="/jobdetails" className="btn btn-soft-success w-100">View Profile</Link>
                                    <Button onClick={() => setModal(true)} className="btn btn-soft-primary w-100">Apply Now</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Paginations perPageData={perPageData} data={jobsGridData} currentPage={currentPage} setCurrentPage={setCurrentPage} isShowingPageLength={true} />

            <Modal isOpen={applyJobmodal} toggle={() => { setModal(); }}>
                <ModalHeader toggle={() => setModal()}>Apply For This Job</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row className='g-3 mx-lg-3'>
                            <div className='col-12'>
                                <Label htmlFor='name'>Full Name</Label>
                                <Input type="text" name="name" id='name'  />
                            </div>
                            <div className='col-6'>
                                <Label htmlFor='email'>Email</Label>
                                <Input type="email" name="email" id='email'  />
                            </div>
                            <div className='col-6'>
                                <Label htmlFor='phone'>Phone Number</Label>
                                <Input type="text" name="phone" id='phone'  />
                            </div>
                            <div className='col-12'>
                                <Label htmlFor='file'>Upload Resume</Label>
                                <Input type="file" name="file" id='file'  />
                            </div>
                            <div className='col-12'>
                                <Label htmlFor='description'>Description</Label>
                                <textarea className="form-control" id="description" name="description" rows="3" ></textarea>
                            </div>
                            <div className='col-12'>
                            <div className="text-end">
                                        <button type='button' className="btn btn-success me-1">Send Application <i className="bx bx-send align-middle"></i></button>
                                        <button type='button' className="btn btn-outline-secondary" onClick={() => setModal()}>Cancel</button>
                                    </div>
                            </div>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}
export default FindJob